import React from "react";
//import logo from '../logo.svg';

export const PageFooter = () => {
    return (<>
        <footer className="App-footer">
        Footer go brr
        </footer>
    </>
    )
};

export default PageFooter;