// Your web app's Firebase configuration
export let firebaseConfig;
if (process.env.NODE_ENV ==='test'){
  //  if (true){
    firebaseConfig = {
        apiKey: "AIzaSyBBG5jqXuk_EnbFdNkMA9qPszwf8GkqPfE",
        authDomain: "letest-test.firebaseapp.com",
        databaseURL: "https://letest-test-default-rtdb.firebaseio.com",
        projectId: "letest-test",
        storageBucket: "letest-test.appspot.com",
        messagingSenderId: "757672957525",
        appId: "1:757672957525:web:7090a8a0ecf24994d866dc"
    };

} else {
    firebaseConfig = {
        apiKey: "AIzaSyAM1st-rZwynhtvaZULRh4G7wBEwbURCTw",
        authDomain: "letest-7fb83.firebaseapp.com",
        databaseURL: "https://letest-7fb83-default-rtdb.firebaseio.com",
        projectId: "letest-7fb83",
        storageBucket: "letest-7fb83.appspot.com",
        messagingSenderId: "31839172112",
        appId: "1:31839172112:web:d054c389ff090715be7c95"
    };
}
