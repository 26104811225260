import React from "react";
const HelpPage = ()=> (
  <>
  <header>Help Goes here</header>
  <section>
    <p>Fry! Stay back! He's too powerful! Interesting. No, wait, the other thing: tedious. How much did you make me? Large bet on myself in round one.</p>
    <p>But existing is basically all I do! Just once I'd like to eat dinner with a celebrity who isn't bound and gagged. <strong> THE BIG BRAIN AM WINNING AGAIN!</strong> <em> I AM THE GREETEST!</em> NOW I AM LEAVING EARTH, FOR NO RAISEN!</p>
    <h2>I was all of history's great robot actors - Acting Unit 0.8; Thespomat; David Duchovny!</h2>
    <p>Oh right. I forgot about the battle. I'm Santa Claus! It's toe-tappingly tragic! Perhaps, but perhaps your civilization is merely the sewer of an even greater society above you! Bender, we're trying our best.</p>
    </section>
    </>
);
export default HelpPage;